import React, { useState, useEffect, useRef, useCallback } from 'react';
import { RetellWebClient } from "retell-client-js-sdk";
import { RefreshCw, Mic, Mail, Briefcase, Cpu, Users, Stethoscope, PhoneOff, Volume2, GraduationCap,Home,Gavel,Brain } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { motion, AnimatePresence } from 'framer-motion';

const Background = () => (
  <div className="fixed inset-0 z-0 bg-[#F0F7FF] overflow-hidden">
    {Array.from({ length: 50 }).map((_, i) => (
      <motion.div
        key={i}
        className="absolute rounded-full"
        style={{
          width: Math.random() * 10 + 2,
          height: Math.random() * 10 + 2,
          background: ['#ffd700', '#ff6347', '#40e0d0'][Math.floor(Math.random() * 3)],
          top: `${Math.random() * 100}%`,
          left: `${Math.random() * 100}%`,
        }}
        animate={{
          y: [0, Math.random() * 100 - 50],
          x: [0, Math.random() * 100 - 50],
          opacity: [0, 1, 0],
        }}
        transition={{
          duration: Math.random() * 10 + 10,
          repeat: Infinity,
          repeatType: "reverse",
        }}
      />
    ))}
  </div>
);

const InterviewTypeSelection = ({ onStartInterview }) => {
  const [selectedType, setSelectedType] = useState(null);

  const interviewTypes = [
    {
      id: 'student-project',
      title: 'Student Competency Interview',
      description: 'Discuss your academic projects and research work',
      icon: Briefcase,
      color: 'from-blue-400 to-blue-600',
      agentId: 'agent_abfceda90b687185adc30046d4',
      promptName: 'generate_call_analysis'
    },
    {
      id: 'semiconductor-ta',
      title: 'Semiconductor Topics TA',
      description: 'Explore semiconductor concepts with an AI teaching assistant',
      icon: Cpu,
      color: 'from-green-400 to-green-600',
      agentId: 'agent_3df6e6226cfe7c81743e6ca701',
      promptName: 'generate_call_analysis_semiconductor'
    },
    {
      id: 'parent-feedback',
      title: 'Parent Program Feedback',
      description: 'Share your thoughts on our educational programs',
      icon: Users,
      color: 'from-yellow-400 to-yellow-600',
      agentId: 'agent_f34f31e5549732322831c1477b',
      promptName: 'generate_call_analysis_parents'
    },
    {
      id: 'healthcare-mock',
      title: 'Healthcare Mock Interview',
      description: 'Practice for your healthcare career interviews',
      icon: Stethoscope,
      color: 'from-red-400 to-red-600',
      agentId: 'agent_ce76188979a794a141ed0e5b24',
      promptName: 'generate_call_analysis_healthcare'
    },
    {
      id: 'student-demo',
      title: 'Student Demo',
      description: 'Talk to Chloe Chen, a 17-year-old junior at University Chapman High School',
      icon: GraduationCap,
      color: 'from-purple-400 to-purple-600',
      agentId: 'agent_89150bb1da30ef4fd07a3f83ed',
      promptName: null
    },
    {
      id: 'home-care',
      title: 'Home is Where the Heart is',
      description: 'Practice discharge education for a cardiac patient',
      icon: Home,
      color: 'from-pink-400 to-pink-600',
      agentId: 'agent_7f93a52005778ee0a4d31012e6', // Replace with actual agent ID
      promptName: 'generate_call_analysis_homecare'
    },
    {
      id: 'legal-consult',
      title: 'Seeking Justice',
      description: 'Consult about a potential medical malpractice case',
      icon: Gavel,
      color: 'from-indigo-400 to-indigo-600',
      agentId: 'agent_c45a9791d3ca7c171a730be8cf', // Replace with actual agent ID
      promptName: 'generate_call_analysis_legal'
    },
    {
      id: 'neuro-consult',
      title: 'Healing Minds',
      description: 'Discuss a neurological case with a specialist',
      icon: Brain,
      color: 'from-teal-400 to-teal-600',
      agentId: 'agent_52fab9701604cae00236983234', // Replace with actual agent ID
      promptName: 'generate_call_analysis_neuro'
    }
  ];

  return (
    <motion.div 
      className="max-w-6xl mx-auto p-4"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.h2 
        className="text-5xl font-bold mb-8 text-center text-primary-dark"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2, duration: 0.5 }}
      >
       Choose a Simulation
      </motion.h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {interviewTypes.map((type, index) => (
          <motion.div 
            key={type.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1, duration: 0.5 }}
          >
            <button
              onClick={() => setSelectedType(type.id)}
              className={`w-full h-full bg-white rounded-xl shadow-lg overflow-hidden transition-all duration-300 ${
                selectedType === type.id ? 'ring-4 ring-primary' : 'hover:shadow-xl'
              }`}
            >
              <div className={`h-full p-6 bg-gradient-to-r ${type.color} flex flex-col justify-between`}>
                <div>
                  <type.icon className="w-12 h-12 text-white mb-4" />
                  <h3 className="text-xl font-semibold text-white mb-2">{type.title}</h3>
                </div>
                <p className="text-white text-opacity-90">{type.description}</p>
              </div>
            </button>
          </motion.div>
        ))}
      </div>
      <motion.div 
        className="mt-8 text-center"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 0.5 }}
      >
        <motion.button
          onClick={() => selectedType && onStartInterview(selectedType, interviewTypes.find(t => t.id === selectedType).agentId, interviewTypes.find(t => t.id === selectedType).promptName)}
          disabled={!selectedType}
          className={`px-8 py-4 text-lg font-semibold rounded-full shadow-md transition-all duration-300 ${
            selectedType
              ? 'bg-primary hover:bg-primary-dark text-white'
              : 'bg-gray-300 text-gray-500 cursor-not-allowed'
          }`}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          {selectedType ? `Start ${interviewTypes.find(t => t.id === selectedType).title}` : 'Select an Interview Type'}
        </motion.button>
      </motion.div>
    </motion.div>
  );
};


const InterviewInProgress = ({ status, onEndCall, retellWebClient }) => {
  const [isAgentSpeaking, setIsAgentSpeaking] = useState(false);

  useEffect(() => {
    const handleAgentStartTalking = () => setIsAgentSpeaking(true);
    const handleAgentStopTalking = () => setIsAgentSpeaking(false);
    const handleDisconnect = () => onEndCall();

    retellWebClient.on("agent_start_talking", handleAgentStartTalking);
    retellWebClient.on("agent_stop_talking", handleAgentStopTalking);
    retellWebClient.on("disconnect", handleDisconnect);

    return () => {
      retellWebClient.off("agent_start_talking", handleAgentStartTalking);
      retellWebClient.off("agent_stop_talking", handleAgentStopTalking);
      retellWebClient.off("disconnect", handleDisconnect);
    };
  }, [retellWebClient, onEndCall]);

  return (
    <div className="h-screen w-full flex flex-col items-center justify-center p-8">
      <motion.h2 
        className="text-4xl font-bold mb-8 text-primary"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        {isAgentSpeaking ? "Speaking" : "Listening"}
      </motion.h2>

      <motion.div
        className="relative w-64 h-64 mb-12"
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ delay: 0.4, duration: 0.5 }}
      >
        <svg className="w-full h-full" viewBox="0 0 100 100">
          <motion.circle
            cx="50"
            cy="50"
            r="45"
            fill="none"
            stroke="#1890FF"
            strokeWidth="2"
            initial={{ scale: 0.8, opacity: 0.3 }}
            animate={isAgentSpeaking ? {
              scale: [0.8, 1, 0.8],
              opacity: [0.3, 0.8, 0.3],
            } : { scale: 0.8, opacity: 0.3 }}
            transition={{
              duration: 1.5,
              repeat: Infinity,
              ease: "easeInOut",
            }}
          />
        </svg>
        <motion.div
          className="absolute inset-0 flex items-center justify-center"
          animate={{ scale: isAgentSpeaking ? 1.1 : 1 }}
          transition={{ duration: 0.5, repeat: Infinity, repeatType: "reverse" }}
        >
          {isAgentSpeaking ? (
            <Volume2
              size={48}
              className="text-primary animate-pulse"
            />
          ) : (
            <Mic
              size={48}
              className="text-primary"
            />
          )}
        </motion.div>
      </motion.div>

      <motion.p 
        className="text-2xl font-semibold mb-12 text-gray-800"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.6, duration: 0.5 }}
      >
      </motion.p>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.8, duration: 0.5 }}
      >
        <button
          onClick={onEndCall}
          className="px-8 py-4 bg-red-500 hover:bg-red-600 text-white font-semibold rounded-full shadow-md transition-all duration-300 flex items-center"
        >
          <PhoneOff size={24} className="mr-2" />
          End Call
        </button>
      </motion.div>
    </div>
  );
};


const TranscriptSection = ({ transcript, onRetry, isLoading }) => {
  return (
    <motion.div 
      className="w-full lg:w-1/2 lg:pr-4 mb-8 lg:mb-0"
      initial={{ opacity: 0, x: -50 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-2xl font-semibold text-primary">Transcript</h3>
        <button 
          onClick={onRetry} 
          className="text-primary hover:text-primary-dark focus:outline-none transition-colors duration-300"
          disabled={isLoading}
        >
          <RefreshCw size={24} className={isLoading ? "animate-spin" : ""} />
        </button>
      </div>
      <motion.div 
        className="bg-white p-6 rounded-lg shadow-md h-[60vh] overflow-y-auto"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2, duration: 0.5 }}
      >
        {isLoading ? (
          <p className="text-gray-500 italic">Loading transcript...</p>
        ) : transcript ? (
          transcript.split('\n').map((line, index) => (
            <motion.p 
              key={index} 
              className="mb-3 text-gray-700"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.05, duration: 0.3 }}
            >
              {line}
            </motion.p>
          ))
        ) : (
          <p className="text-gray-500 italic">Transcript not available. Please try again.</p>
        )}
      </motion.div>
    </motion.div>
  );
};

const AnalysisSection = ({ analysis, onRetry, isLoading }) => {
  const analysisRef = useRef(null);

  useEffect(() => {
    if (analysisRef.current) {
      analysisRef.current.scrollTop = analysisRef.current.scrollHeight;
    }
  }, [analysis]);

  return (
    <motion.div 
      className="w-full lg:w-1/2 lg:pl-4"
      initial={{ opacity: 0, x: 50 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-2xl font-semibold text-primary">Call Analysis</h3>
        <button 
          onClick={onRetry} 
          className="text-primary hover:text-primary-dark focus:outline-none transition-colors duration-300"
          disabled={isLoading}
        >
          <RefreshCw size={24} className={isLoading ? "animate-spin" : ""} />
        </button>
      </div>
      <motion.div 
        ref={analysisRef}
        className="bg-white p-6 rounded-lg shadow-md h-[60vh] overflow-y-auto"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2, duration: 0.5 }}
      >
        {isLoading && analysis.length === 0 ? (
          <p className="text-gray-500 italic">Generating analysis...</p>
        ) : (
          <ReactMarkdown 
            rehypePlugins={[rehypeRaw]} 
            remarkPlugins={[remarkGfm]}
            className="prose max-w-none"
          >
            {analysis || 'Analysis not available. Please try again.'}
          </ReactMarkdown>
        )}
      </motion.div>
    </motion.div>
  );
};

const EmailForm = ({ onSendEmail, onCancel }) => {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSendEmail(email);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    >
      <motion.div 
        className="bg-white p-8 rounded-2xl shadow-2xl max-w-md w-full"
        initial={{ scale: 0.9, y: 20 }}
        animate={{ scale: 1, y: 0 }}
        transition={{ duration: 0.3 }}
      >
        <h3 className="text-2xl font-bold mb-6 text-primary">Send Email</h3>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Email Address</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-primary focus:border-primary transition-all duration-300"
              required
            />
          </div>
          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={onCancel}
              className="px-4 py-2 bg-gray-200 hover:bg-gray-300 text-gray-800 rounded-md transition-all duration-300"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-primary hover:bg-primary-dark text-white rounded-md transition-all duration-300"
            >
              Send
            </button>
          </div>
        </form>
      </motion.div>
    </motion.div>
  );
};



function CallDetails({ callId, promptName, onStartNewCall }) {
  const [transcript, setTranscript] = useState(null);
  const [analysis, setAnalysis] = useState('');
  const [transcriptLoading, setTranscriptLoading] = useState(true);
  const [analysisLoading, setAnalysisLoading] = useState(true);
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [emailStatus, setEmailStatus] = useState(null);
  const abortControllerRef = useRef(null);

  const fetchTranscript = async () => {
    setTranscriptLoading(true);
    try {
      const response = await fetch(`https://schooljoy-staging.eastus2.cloudapp.azure.com/get_retell_call_details?call_id=${callId}`);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Failed to fetch call details: ${JSON.stringify(errorData)}`);
      }
      const data1 = await response.json();
      const data = JSON.parse(data1);
      console.log('Received call details:', data);
      setTranscript(data.transcript);
    } catch (error) {
      console.error('Error fetching transcript:', error);
      setTranscript(null);
    } finally {
      setTranscriptLoading(false);
    }
  };

  const fetchAnalysis = useCallback(async () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();

    setAnalysisLoading(true);
    setAnalysis('');
    try {
      const response = await fetch(
        `https://schooljoy-staging.eastus2.cloudapp.azure.com/generate_call_analysis?call_id=${callId}&prompt_name=${promptName}`,
        { signal: abortControllerRef.current.signal }
      );
      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value);
        setAnalysis(prevAnalysis => prevAnalysis + chunk);
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error('Error fetching call analysis:', error);
        setAnalysis('Error: Failed to fetch call analysis');
      }
    } finally {
      setAnalysisLoading(false);
    }
  }, [callId, promptName]);

  useEffect(() => {
    if (callId) {
      const timer = setTimeout(() => {
        fetchTranscript();
        fetchAnalysis();
      }, 500);

      return () => {
        clearTimeout(timer);
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }
      };
    }
  }, [callId, promptName, fetchAnalysis]);

  const handleSendEmail = async (email) => {
    try {
      setEmailStatus('Sending...');
      const response = await fetch('https://schooljoy-staging.eastus2.cloudapp.azure.com/send_email_interview/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          callId,
          promptName,
          transcript,
          analysis,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to send email');
      }

      setEmailStatus('Email sent successfully!');
    } catch (error) {
      console.error('Error sending email:', error);
      setEmailStatus('Failed to send email. Please try again.');
    } finally {
      setShowEmailForm(false);
    }
  };

  return (
    <motion.div 
      className="mt-8"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h2 className="text-3xl font-bold mb-8 text-primary text-center">Call Results</h2>
      
      <div className="flex flex-col lg:flex-row space-y-8 lg:space-y-0 lg:space-x-8">
        <TranscriptSection 
          transcript={transcript} 
          onRetry={fetchTranscript}
          isLoading={transcriptLoading}
        />
        <AnalysisSection 
          analysis={analysis} 
          onRetry={fetchAnalysis}
          isLoading={analysisLoading}
        />
      </div>
      
      <motion.div 
        className="mt-12 flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 0.5 }}
      >
        <motion.button
          onClick={onStartNewCall}
          className="px-8 py-4 bg-blue-500 hover:bg-blue-600 text-white font-semibold rounded-full shadow-md transition-all duration-300"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Start New Call
        </motion.button>
        <motion.button
          onClick={() => setShowEmailForm(true)}
          className="px-8 py-4 bg-green-500 hover:bg-green-600 text-white font-semibold rounded-full shadow-md flex items-center justify-center transition-all duration-300"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <Mail className="mr-2" size={20} />
          Send Email
        </motion.button>
      </motion.div>

      {emailStatus && (
        <motion.p
          className="mt-4 text-center text-lg text-gray-700"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {emailStatus}
        </motion.p>
      )}

      <AnimatePresence>
        {showEmailForm && (
          <EmailForm
            onSendEmail={handleSendEmail}
            onCancel={() => setShowEmailForm(false)}
          />
        )}
      </AnimatePresence>
    </motion.div>
  );
}

const App = () => {
  const [status, setStatus] = useState('Ready to start the call');
  const [isCallActive, setIsCallActive] = useState(false);
  const [retellWebClient, setRetellWebClient] = useState(null);
  const [callId, setCallId] = useState(null);
  const [currentView, setCurrentView] = useState('selection');
  const [promptName, setPromptName] = useState(null);

  useEffect(() => {
    const client = new RetellWebClient();
    setRetellWebClient(client);

    const handleCallStarted = () => {
      console.log('Call started');
      setStatus('Call in progress');
      setIsCallActive(true);
    };

    const handleCallEnded = () => {
      console.log('Call ended by agent or system');
      setStatus('Call ended');
      setIsCallActive(false);
      setCurrentView('results');
    };

    const handleDisconnect = (data) => {
      console.log('Disconnected from room:', data);
      handleCallEnded();
    };

    const handleError = (error) => {
      console.error('An error occurred:', error);
      setStatus(`Error: ${error.message}`);
      setIsCallActive(false);
      setCurrentView('selection');
    };

    client.on('call_started', handleCallStarted);
    client.on('call_ended', handleCallEnded);
    client.on('disconnect', handleDisconnect);
    client.on('error', handleError);

    return () => {
      client.off('call_started', handleCallStarted);
      client.off('call_ended', handleCallEnded);
      client.off('disconnect', handleDisconnect);
      client.off('error', handleError);
    };
  }, []);

  const startCall = async (interviewType, agentId, promptName) => {
    try {
      setStatus('Starting the call...');
  
      const response = await fetch('https://schooljoy-staging.eastus2.cloudapp.azure.com/create-web-call/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          agent_id: agentId,
        }),
      });
  
      if (!response.ok) {
        const errorText = await response.json();
        throw new Error(`Server Error: ${response.status} - ${errorText}`);
      }
  
      const responseText = await response.json();
      console.log('Server response:', responseText);
      const createCallResponse = JSON.parse(responseText);
      console.log('Parsed response:', createCallResponse);
      const { access_token: accessToken, call_id: newCallId } = createCallResponse;
  
      if (!accessToken) {
        throw new Error('No access token received from server.');
      }
  
      await retellWebClient.startCall({ accessToken });
      setIsCallActive(true);
      setCallId(newCallId);
      setPromptName(promptName);
      setStatus(`Call in progress`);
      console.log(`Call ID: ${newCallId}`); // Log the call ID to the console
      setCurrentView('call');
    } catch (error) {
      console.error('Error starting the call:', error);
      setStatus(`Failed to start the call - ${error.message}`);
    }
  };

  const endCall = () => {
    if (isCallActive && retellWebClient) {
      retellWebClient.stopCall();
      setStatus('Ending call...');
      setCurrentView('results');
    }
  };

  const handleStartNewCall = () => {
    setCallId(null);
    setStatus('Ready to start the call');
    setCurrentView('selection');
  };

  return (
    <div className="relative min-h-screen font-sans overflow-hidden">
      <Background />
      <div className="relative z-10 w-full max-w-6xl mx-auto p-4">
        <motion.div 
          className="flex justify-center mb-8"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          <img src="/SchoolJoy Logo_Blue.png" alt="SchoolJoy Logo" className="h-16 md:h-20" />
        </motion.div>
        
        <AnimatePresence mode="wait">
          {currentView === 'selection' && (
            <InterviewTypeSelection onStartInterview={(interviewType, agentId, promptName) => startCall(interviewType, agentId, promptName)} />
          )}
          {currentView === 'call' && (
            <InterviewInProgress status={status} onEndCall={endCall} retellWebClient={retellWebClient} />
          )}
          {currentView === 'results' && (
            <CallDetails callId={callId} promptName={promptName} onStartNewCall={handleStartNewCall} />
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default App;